import { Attr, Model, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard, Buyer } from '@/models/index'
import request from '@/utils/request'
import { Notification } from 'element-ui'

@Model()
export default class PullRequest extends ApplicationRecord {
  static jsonapiType = 'pull_requests'

  @Attr() amount!: number
  @Attr() amountCents!: number
  @Attr() status!: string
  @Attr() requestId!: string
  @Attr() errorDetails!: string
  @Attr() createdAt!: string

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() buyer!: Buyer

  async refund(discard = false) {
    const params = {
      pull_request: {
        discard: discard
      }
    }

    try {
      await request.put(`/admin/pull_requests/${this.id}/refund`, params)

      this.status = 'refunded'
      Notification({
        title: 'Refund success',
        message: 'Pull Request has been refunded',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  static async fixWaiting() {
    try {
      await request.get('/admin/pull_request/fix_waiting')

      Notification({
        title: 'Fix Waiting launched',
        message: 'Fix Waiting has been launched',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }
}
