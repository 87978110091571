import { Notification } from 'element-ui'
import { Model, Attr, BelongsTo, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Card, CardDistribution, Customer, GiftCard, CustomerInvoice } from '@/models/index'
import request from '@/utils/request'

@Model()
export default class Order extends ApplicationRecord {
  static jsonapiType = 'customer_orders'

  @Attr() id!: string
  @Attr() amount!: number
  @Attr() discountedAmount!: number
  @Attr() number!: number
  @Attr() status!: string
  @Attr() paymentStatus!: string
  @Attr() deliveryStatus!: string
  @Attr() deliveryErrorDetails!: string
  @Attr() createdAt!: Date
  @Attr() paidAt!: Date
  @Attr() fromCaretaker!: boolean

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() customer!: Customer
  @BelongsTo() customerInvoice!: CustomerInvoice

  @HasMany() cardDistributions!: CardDistribution[]
  @HasMany() cards!: Card[]

  public static async deliverAll() {
    try {
      await request.get('/admin/customer_orders/deliver_all')

      Notification({
        title: 'Delivery launched',
        message: 'Delivery has been launched',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async deliver() {
    const params = {
      data: {
        id: this.id,
        type: 'customer_orders'
      }
    }

    try {
      const { data } = await request.put(`/admin/customer_orders/${this.id}/deliver`, params)
      this.status = data.attributes.status
      this.deliveryStatus = data.attributes.deliveryStatus

      Notification({
        title: 'Delivery launched',
        message: 'Delivery has been launched',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async cancelFulfill() {
    const params = {
      data: {
        id: this.id,
        type: 'customer_orders'
      }
    }

    try {
      const { data } = await request.put(`/admin/customer_orders/${this.id}/cancel_fulfill`, params)
      this.status = data.attributes.status

      Notification({
        title: 'Customer Order rollback',
        message: 'Customer Order has been rollback to process',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async cancelDelivery() {
    const params = {
      data: {
        id: this.id,
        type: 'customer_orders'
      }
    }

    try {
      const { data } = await request.put(`/admin/customer_orders/${this.id}/cancel_delivery`, params)
      this.deliveryStatus = data.attributes.deliveryStatus

      Notification({
        title: 'Delivery cancelled',
        message: 'Delivery has been cancelled',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public isNotDelivered() {
    return this.deliveryStatus === 'undelivered' || this.deliveryStatus === 'in_delivery'
  }

  public isPullError() {
    return this.deliveryStatus === 'pull_error'
  }

  public isPaid() {
    return this.paymentStatus === 'paid'
  }

  public isRefund() {
    return this.paymentStatus === 'refunded'
  }

  public isFailure() {
    return this.paymentStatus === 'failure'
  }

  public isDelivered() {
    return this.deliveryStatus === 'delivered'
  }

  public isFulfilled() {
    return this.status === 'fulfilled'
  }

  public isProcessing() {
    return this.status === 'processing'
  }

  public isRefundable() {
    return this.isPaid() && (this.isNotDelivered() || this.isPullError())
  }

  public async pay() {
    const params = {
      data: {
        id: this.id,
        type: 'customer_orders'
      }
    }

    try {
      await request.put(`/admin/customer_orders/${this.id}/pay`, params)
      this.paymentStatus = 'paid'

      Notification({
        title: 'Order is paid',
        message: 'Order is now paid',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      Notification({
        title: "Order can't be paid",
        message: 'Unable to paid the order',
        type: 'error',
        duration: 2000
      })
    }
  }

  public async refund() {
    const params = {
      data: {
        id: this.id,
        type: 'customer_orders'
      }
    }

    try {
      await request.put(`/admin/customer_orders/${this.id}/refund`, params)
      this.paymentStatus = 'refunded'

      Notification({
        title: 'Commande remboursée',
        message: 'La commande a été remboursée',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      Notification({
        title: "La commande non remboursée",
        message: 'Impossible de rembourser la commande',
        type: 'error',
        duration: 2000
      })
    }
  }

  public getColorByName() {
    let name = ''
    let hash = 0

    if (this.customer?.firstname && this.customer?.lastname) name = this.customer.firstname + this.customer.lastname

    if (name.length === 0) return hash
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash)
      hash = hash & hash
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 255
      color += ('00' + value.toString(16)).substr(-2)
    }
    return color
  }
}
