import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Sku, GiftCardSeller } from '@/models/index'

@Model()
export default class GiftCardSellerSku extends ApplicationRecord {
  static jsonapiType = 'gift_card_seller_skus'

  @Attr() id!: string
  @Attr() metadata!: any

  @BelongsTo() giftCardSeller!: GiftCardSeller
  @BelongsTo() sku!: Sku
}
