import {Model, Attr, BelongsTo, HasMany} from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard, GiftCardSellerSku, Seller, Wholesaler } from '@/models/index'

@Model()
export default class GiftCardSeller extends ApplicationRecord {
  static jsonapiType = 'gift_card_sellers'

  @Attr() id!: string
  @Attr() default!: boolean
  @Attr() metadata!: any
  @Attr() retrievalMode!: string
  @Attr() byGiftCardNameAlphabetical!: boolean
  @Attr() apiClient!: string
  @Attr() metadataPerSku!: boolean

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() seller!: Seller
  @BelongsTo() wholesaler!: Wholesaler

  @HasMany() giftCardSellerSkus!: GiftCardSellerSku[]
}
