import { Notification } from 'element-ui'
import { Model, Attr, HasMany, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Buyer, Wholesaler, Seller, Order, BankTransfer } from '@/models/index'
import request from '@/utils/request'

@Model()
export default class Invoice extends ApplicationRecord {
  static jsonapiType = 'invoices'

  @Attr() id!: string
  @Attr() number!: string
  @Attr() customNumber!: string
  @Attr() amount!: number
  @Attr() paymentStatus!: string
  @Attr() paymentMode!: string
  @Attr() paymentLimitAt!: string
  @Attr() createdAt!: Date
  @Attr() issuedAt!: Date
  @Attr() file!: string
  @Attr() mode!: string
  @Attr() comment!: string
  @Attr({ persist: false }) fileBlob!: any

  @BelongsTo() seller!: Seller
  @BelongsTo() buyer!: Buyer
  @BelongsTo() wholesaler!: Wholesaler
  @BelongsTo() invoice!: Invoice

  @HasMany() orders!: Order[]
  @HasMany() bankTransfers!: BankTransfer[]

  public isPaid(): boolean {
    return this.paymentStatus === 'paid'
  }

  public isUnpaid(): boolean {
    return this.paymentStatus === 'unpaid'
  }

  public async generate() {
    const params = {
      data: {
        id: this.id,
        type: 'invoices'
      }
    }

    try {
      const { data } = await request.get(`/admin/invoices/${this.id}/generate`, params)
      this.file = data.file

      Notification({
        title: 'Invoice PDF generated',
        message: 'Invoice PDF generated',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }
}
